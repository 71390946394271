const Accordion = {
  init: () => {


/*
    if(document.querySelector('#exhibitions')  != null) {
      if(document.querySelector('.ausstellungen')  != null) {
      document.querySelector('.ausstellungen').classList.add('active');
      document.querySelector('.ausstellungen .link-top').classList.add('active');
      }
    }
*/

    function initAcc(elem, option){
      document.addEventListener('click', function (e) {




          if (!e.target.matches(elem+' .a-btn')) return;
          else{
              if(!e.target.parentElement.classList.contains('active')){
                  if(option==true){
                      var elementList = document.querySelectorAll(elem+' .a-container');
                      Array.prototype.forEach.call(elementList, function (e) {
                          e.classList.remove('active');
                      });
                  }   

                  document.querySelectorAll('.a-container').forEach((button) => {
                    button.classList.remove('active');
                  }); 
                    document.querySelectorAll('.a-container-2').forEach((button) => {
                      button.classList.remove('active-2');
                });       
                  e.target.parentElement.classList.add('active');

            
              }else{
                
                
                  e.target.parentElement.classList.remove('active');
              }
          }
      });
    }

    function initAcc2(elem2, option2){
      document.addEventListener('click', function (e) {



          if (!e.target.matches(elem2+' .a-btn-2')) return;
          else{
              if(!e.target.parentElement.classList.contains('active-2')){
                  if(option2==true){
                      var elementList2 = document.querySelectorAll(elem2+' .a-container-2');
                      Array.prototype.forEach.call(elementList2, function (e) {
                          e.classList.remove('active-2');
                      });
                  }   
                  document.querySelectorAll('.a-container-2').forEach((button2) => {
                    button2.classList.remove('active-2');
                });         
                  e.target.parentElement.classList.add('active-2');
              }else{
                  e.target.parentElement.classList.remove('active-2');
              }
          }
      });
    }
    initAcc('.accordion.v2', false);
    initAcc2('.accordion.v2', false);
  }
};

export default Accordion;
