import "./_lib/modernizr";
import "../scss/application.scss";
import MagicGrid from "magic-grid"
import Isotope from 'isotope-layout';
import LazyLoad from "vanilla-lazyload";

//import jquery from "jquery";

//window.$ = window.jQuery = jquery;

//import Website from "./_modules/website";
import Accordion from "./_modules/accordion";
import Swup from 'swup';
import SwupBodyClassPlugin from '@swup/body-class-plugin';
















var baseonly = document.querySelectorAll('.base-only');
baseonly.forEach((base) => {
  base.addEventListener('click', () => {
    document.querySelectorAll('.a-container').forEach((button) => {
      button.classList.remove('active');
    }); 
  });
});

var menuButton = document.querySelector('.mobile-bar__trigger');
var mobileMenu = document.querySelector('.mobile-menu');

menuButton.addEventListener('click', function() {
  mobileMenu.classList.toggle('active');
  menuButton.classList.toggle('active');
  document.body.classList.toggle('no-scroll');
});

Accordion.init();

all();
console.log("does this start")

window.addEventListener('load', function () {
  all();
});

const options = { animateHistoryBrowsing: true, plugins: [new SwupBodyClassPlugin()]};
const swup = new Swup(
    options
    );

swup.on('contentReplaced', all);


swup.on('willReplaceContent', () => {
  document.querySelector('.mobile-bar__trigger').classList.remove('active');
  document.querySelector('.mobile-menu').classList.remove('active');
});

function swupActiveLinks(){

  document.querySelectorAll('.a-container').forEach((button) => {
    button.classList.remove('active');
  }); 


  let currentPath = window.location.pathname;
  let links = document.querySelectorAll('.menu a'); // <- put your link selector here
  for (const link of links) {
      try {
          let linkPath = (new URL(link.href)).pathname;
          link.ariaCurrent = linkPath === currentPath ? 'page' : false;
  
          const activeChildren = document.querySelectorAll('a[aria-current=page]');
  
          // Loop through the active child elements
          for (var i = 0; i < activeChildren.length; i++) {
              // Get the parent element of the active child
              var parent = activeChildren[i].closest(".a-container");
              console.log(parent);
  
              // Add the active class to the parent element
              parent.classList.add('active');
          }
      } catch (error) {
          // Handle the case where the URL is invalid
          console.error('Invalid URL:', link.href);
      }
  }
}


function swupActiveLinks_reload(){



  let currentPath = window.location.pathname;
  let links = document.querySelectorAll('.menu a'); // <- put your link selector here
  for (const link of links) {
      let linkPath = (new URL( link.href )).pathname;
      link.ariaCurrent = linkPath == currentPath ? 'page' : false;

      const activeChildren = document.querySelectorAll('a[aria-current=page]');

      // Loop through the active child elements
      for (var i = 0; i < activeChildren.length; i++) {
        // Get the parent element of the active child
        var parent = activeChildren[i].closest(".a-container");
        console.log(parent);
      
        // Add the active class to the parent element
       // parent.classList.add('active');
      }

  }

}
swup.on('contentReplaced', () => {
  swupActiveLinks_reload(); // trigger after swup
});
swupActiveLinks(); // trigger on page load








function all() {


  if (document.querySelector('.year__content') != null) {

    var iso = new Isotope('.year__content', {
      itemSelector: '.year__content__item',
      layoutMode: 'fitRows'
    });
  
  
    // Bind filter button click
    var filtersElem = document.querySelector('#filters');
    filtersElem.addEventListener('click', function(event) {
      var filterValue = event.target.getAttribute('data-filter');
      // Use matching filter function
      filterValue = filterValue;
      iso.arrange({ filter: filterValue });
    });
  
    // Change is-checked class on buttons
    var buttonGroups = document.querySelectorAll('.button-group');
    for (var i=0, len = buttonGroups.length; i < len; i++) {
      var buttonGroup = buttonGroups[i];
      radioButtonGroup(buttonGroup);
    }
  
    function radioButtonGroup(buttonGroup) {
      buttonGroup.addEventListener('click', function(event) {
    
        buttonGroup.querySelector('.is-checked').classList.remove('is-checked');
        event.target.classList.add('is-checked');
      });
    }
    }


  var lazyLoadInstance = new LazyLoad({
    // Your custom settings go here
  });


  if(document.querySelector('.readmore') != null) {
 // Add a click event listener to the "Read More" button
 document.querySelector('.single__exhibition__right__description__readmore').addEventListener('click', function () {
  // Toggle the 'active' class on the description element
  console.log("tst");
  document.querySelector('.single__exhibition__right__description__readmore').classList.add('active');
  document.querySelector('.readmore').classList.add('active');
});



 // Add a click event listener to the "Read More" button
 document.querySelector('.single__exhibition__right__description__readmore__close').addEventListener('click', function () {
  console.log("tst2")
  // Toggle the 'active' class on the description element
  document.querySelector('.single__exhibition__right__description__readmore').classList.remove('active');

  document.querySelector('.readmore').classList.remove('active');
});
  }

  if(document.querySelector('#searchInput') != null) {
    // Get the search input element
    const searchInput = document.getElementById('searchInput');
    
    // Get all the year container elements
    const yearContainers = document.querySelectorAll('#artists > div');
    
    // Add an event listener to the search input
    searchInput.addEventListener('input', function() {
      console.log("test");
      const searchTerm = searchInput.value.toLowerCase();
      yearContainers.forEach(function(container) {
        let hasMatch = false;
        const artistTitles = container.querySelectorAll('h3');
        artistTitles.forEach(function(title) {
          const text = title.textContent.toLowerCase();
          const parent = title.parentElement;
          if (text.includes(searchTerm)) {
            hasMatch = true;
            parent.style.display = 'block';
          } else {
            parent.style.display = 'none';
          }
        });
        const yearTitle = container.querySelector('h2');
        if (hasMatch) {
          yearTitle.style.display = 'block';
        } else {
          yearTitle.style.display = 'none';
        }
      });
    });
    }


  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });


    
   
    

  if (window.innerWidth < 1024) {
var headerLogo = document.querySelector('.mobile-logo');

window.onscroll = function() {
  if (window.pageYOffset > 50) {
    headerLogo.classList.add('small');
  } else {
    headerLogo.classList.remove('small');
  }
}
  }

  if (window.innerWidth >= 1024) {

  if(document.querySelector('.gallery-normal') != null || document.querySelector('.gallery-iso') != null){

    if(document.querySelector('.gallery-normal') != null) {
    document.querySelector('.gallery-normal').classList.add('active');


  let magicGrid = new MagicGrid({
    container: ".gallery-normal", // Required. Can be a class, id, or an HTMLElement.
    static: true, // Required for static content.
    animate: true, // Optional.
    gutter: 28,
  });

 
  

  magicGrid.listen();
} 
  // Initialize Isotope


 

}
  
  setTimeout(function () {
  if(document.querySelector('.home') != null) {
    document.querySelector('.gallery-home').classList.add('active');

  let magicGrid = new MagicGrid({
    container: ".gallery-home", // Required. Can be a class, id, or an HTMLElement.
    static: true, // Required for static content.
    animate: true, // Optional.
  });
  

  magicGrid.listen();
}



}, 1000);



if(document.querySelector('.home')  != null) {
setTimeout(function () {
document.querySelector('.logo').classList.add('active');

}, 1000);

setTimeout(function () {
  document.querySelector('.home').classList.add('small-active');
  
  }, 1500);
}
if(document.querySelector('.home')  != null) {
setTimeout(function () {
    document.querySelector('.gallery-home').classList.add('active-text');

}, 1800);
}
}
}




